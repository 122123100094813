import { ReactNode } from "react";
import styled from "styled-components";
import {
  COLOR_BORDER_IDLE_PAGE,
  COLOR_SIDEBAR,
  COLOR_TEXT,
  COLOR_TITLE,
  COLOR_WHITE,
} from "../constants/cts_colors";
import { IoIosSearch } from "react-icons/io";

const HeaderSearchAndProfile = ({}: {}) => {
  return (
    <Header>
      <div className="search-container">
        <input type="text" placeholder="Rechercher" />
        <IoIosSearch size={20} className="icon" />
      </div>
    </Header>
  );
};

export default HeaderSearchAndProfile;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Header = styled.div`
  background-color: ${COLOR_WHITE};
  border-bottom: 1px solid ${COLOR_BORDER_IDLE_PAGE};
  padding: 24px 40px 24px 32px;
  position: relative;

  .search-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
      position: absolute;
      left: 42px;
    }

    input {
      padding: 10px 10px 10px 32px;
      width: 615px;
      height: 56px;
      gap: 8px;
      border-radius: 100px;
      font-family: "Ubuntu", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.38px;
      text-align: left;
      outline: none;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      border: none;
      background-color: ${COLOR_SIDEBAR};
      color: ${COLOR_TEXT};
    }
  }
`;
