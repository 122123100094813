import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import backgroundImgSrc from "../../assets/images/bg-1920-1080.jpg";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { COLOR_BACKGROUND, COLOR_TITLE } from "../../constants/cts_colors";
import { RiLoader2Line } from "react-icons/ri";
import { DASHBOARD_IFRAME_LINK } from "../../constants/cts_iframe";
import LoadingContainer from "../../components/LoadingContainer";

const Dashboard = () => {
  const { user } = useAuthentication();

  const iframe = useRef(null);

  const [isLoading, _setIsLoading] = useState<boolean>(true);

  // set the title of the page
  useEffect(() => {
    document.title = "Accueil | Sorbonne";
  }, []);

  return (
    <Wrapper>
      {/* HELLO WORLD */}
      {user && (
        <h1 className="title">
          Bienvenue, {user.firstName} {user.lastName}
        </h1>
      )}

      <div
        style={{
          height: "100%",
          display: isLoading ? "flex" : "none",
        }}
      >
        <LoadingContainer />
      </div>

      {/* IFRAME */}
      <Iframe
        style={{ display: isLoading ? "none" : "flex" }}
        ref={iframe}
        src={DASHBOARD_IFRAME_LINK}
        onLoad={() => {
          _setIsLoading(false);
        }}
        title="Agents Iframe"
      />
    </Wrapper>
  );
};

export default Dashboard;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  /* background: url(${backgroundImgSrc}) no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .title {
    position: absolute;
    top: 20px;
    right: 20px;
    color: "#4c5773";
    font-size: 1.5rem;
    opacity: 0.6;
    text-shadow: 2px 0 ${COLOR_BACKGROUND}, -2px 0 ${COLOR_BACKGROUND},
      0 2px ${COLOR_BACKGROUND}, 0 -2px ${COLOR_BACKGROUND},
      1px 1px ${COLOR_BACKGROUND}, -1px -1px ${COLOR_BACKGROUND},
      1px -1px ${COLOR_BACKGROUND}, -1px 1px ${COLOR_BACKGROUND};
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
