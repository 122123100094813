import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  sidebarElements,
  sidebarAdminElements,
} from "../constants/cts_sidebar";
import { getPageUrl } from "../locales/i18n";
import PATH from "../constants/cts_routes";
import {
  COLOR_BLUE_BUTTON,
  COLOR_BLUE_MEDIUM_DARK,
  COLOR_LINK,
  COLOR_LINK_ACTIVE,
  COLOR_SIDEBAR,
  COLOR_TEXT,
} from "../constants/cts_colors";
import { useAuthentication } from "../common/contexts/authenticationContext";
import logoSrc from "../assets/images/logo.svg";
import newLogoSrc from "../assets/images/new_logo.png";
import { SIDEBAR_WIDTH } from "../constants/cts_sizes";
import { useEffect, useState } from "react";
import NotificationsBtn from "./NotificationsBtn";
import { INotificationInfos } from "../interfaces/notification";

const Sidebar = ({
  handleLogout,
  notifications,
}: {
  handleLogout?: Function;
  notifications: INotificationInfos[];
}) => {
  const { user, onLogout } = useAuthentication();
  const [redirectToLogIn, _setRedirectToLogIn] = useState<boolean>(false);

  // MODALS
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  return (
    <Wrapper>
      {/* { */}
      <div className="brand">
        <StyledNavLink
          to={getPageUrl(PATH.home)}
          className="logo-link"
          // className={({ isActive }) => (isActive ? "active" : "")}
        >
          <img src={newLogoSrc} className="logo" />
        </StyledNavLink>
      </div>
      {/* } */}
      <SideBarContent>
        <div>
          {sidebarElements.map((element) => {
            return (
              <div className="element" key={sidebarElements.indexOf(element)}>
                <StyledNavLink
                  to={getPageUrl(element.to)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  key={sidebarElements.indexOf(element)}
                >
                  <i
                    className={
                      window.location.pathname === `/${element.to}`
                        ? element.filledLogo
                        : element.logo
                    }
                  ></i>
                  {element.name}
                </StyledNavLink>
              </div>
            );
          })}
          {user && user["role"] === 1 && (
            <div>
              <AdminSection>
                <hr />
                <p>Administrateur</p>
                <hr />
              </AdminSection>
              {sidebarAdminElements.map((adminElement) => {
                return (
                  <div
                    className="element"
                    key={sidebarAdminElements.indexOf(adminElement)}
                  >
                    <StyledNavLink
                      to={getPageUrl(adminElement.to)}
                      className={({ isActive }) => (isActive ? "active" : "")}
                      key={sidebarAdminElements.indexOf(adminElement)}
                    >
                      <i
                        className={
                          window.location.pathname === `/${adminElement.to}`
                            ? adminElement.filledLogo
                            : adminElement.logo
                        }
                      ></i>
                      {adminElement.name}
                    </StyledNavLink>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div>
          <UserAndNotifications>
            <NotificationsBtn notifications={notifications} />
            <UserDiv>
              {user ? user.firstName : ""} {user ? user.lastName : ""}
            </UserDiv>
          </UserAndNotifications>

          <hr style={{ marginRight: 4 }} />

          {user && ( // check if user connected
            <div className="logout-element">
              <LogoutButton
                onClick={() => {
                  if (handleLogout) handleLogout();
                }}
              >
                <i className="ri-logout-box-r-line"></i>
                <p>Déconnexion</p>
              </LogoutButton>
            </div>
          )}
        </div>
      </SideBarContent>
    </Wrapper>
  );
};

export default Sidebar;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_SIDEBAR};
  min-width: ${SIDEBAR_WIDTH};
  margin: 0;
  overflow-y: auto;

  .brand {
    float: none;
    margin-right: 0;
    font-size: 18px;
    line-height: 1;

    .logo {
      width: 150px;
    }
  }

  .element {
    display: flex;
    align-items: center;
    line-height: 40px;
    font-size: 14px;
    text-align: left;
    i {
      font-size: 16px;
    }
  }
`;

const LogoutButton = styled.div`
  display: flex;
  flex-wrap: nowrap;
  text-decoration: none;
  color: ${COLOR_TEXT};
  cursor: pointer;
  margin: 0 16px;
  padding: 11px 0;
  line-height: 18px;
  font-size: 14px;

  p {
    margin-left: 16px;
  }

  i {
    position: absolute;
    left: 8px;
  }

  &:hover {
    color: ${COLOR_BLUE_BUTTON};
    p {
      color: ${COLOR_BLUE_BUTTON};
    }
  }

  &.active {
    color: ${COLOR_BLUE_BUTTON};
  }

  .logo {
    width: 150px;
  }
`;

const AdminSection = styled.div`
  margin: 16px 0px 8px 0px;
  display: flex;
  flex-direction: row;
  line-height: 18px;
  font-size: 14px;
  color: ${COLOR_TEXT};
  justify-content: space-between;
  margin-right: 4px;
  gap: 4px;

  hr {
    width: 100%;
    margin: auto;
    color: ${COLOR_TEXT};
  }

  i {
    font-size: 18px;
    position: absolute;
    left: 8px;
  }

  .logo {
    width: 150px;
  }
`;

const SideBarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  text-decoration: none;
  color: ${COLOR_TEXT};
  cursor: pointer;
  margin: 4px 12px;
  line-height: 18px;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  gap: 16px;
  border-radius: 8px;
  padding: 8px;
  i {
    /* position: absolute; */
    /* left: 8px; */
  }

  &.logo-link {
    background-color: transparent !important;
  }

  &:hover {
    color: rgba(29, 39, 105, 0.8);
  }

  &.active {
    color: ${COLOR_BLUE_MEDIUM_DARK};
    font-weight: bold;
    background-color: ${COLOR_LINK_ACTIVE};
  }
`;

const UserAndNotifications = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 12px;
`;

const UserDiv = styled.div`
  color: ${COLOR_TEXT};
`;
