import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import {
  COLOR_BORDER_IDLE_PAGE,
  COLOR_BTN,
  COLOR_BTN_HOVER,
  COLOR_TEXT_TABLE_ROW_ACTIONS,
  COLOR_WHITE,
} from "../../constants/cts_colors";
import { FiMoreVertical } from "react-icons/fi";
import { FaArchive, FaWpforms, FaCocktail } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdGrade } from "react-icons/md";
import { RiZzzLine } from "react-icons/ri";
import { MdOutlineWorkOutline } from "react-icons/md";

const TableRowActions = ({
  row,
  actions,
  onActionSelected,
  tableScrollPosition,
  tableHeadBottomPosition,
}: {
  row: any;
  actions: Object;
  onActionSelected: Function;
  tableScrollPosition: number;
  tableHeadBottomPosition: number | null;
}) => {
  const [isOpend, _setIsOpened] = useState<boolean>(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // DETECT WHEN THE USER CLICKS OUTSIDE THE ACTIONS POPUP COMPONENT
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      const actionsContainer = btnRef.current;
      if (
        actionsContainer &&
        !actionsContainer.contains(event.target as Node)
      ) {
        _setIsOpened(false);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [btnRef]);

  // SET THE MENU POSITION ON TABLE SCROLL
  useEffect(() => {
    const btn = btnRef.current;
    const menu = menuRef.current;

    if (btn && menu) {
      let top = btn.getBoundingClientRect().top;
      const left =
        btn.getBoundingClientRect().x - menu.getBoundingClientRect().width - 20;

      // if the length of menu passes the bottom view, set the top higher
      const menuHeight = menu.getBoundingClientRect().height;
      const bottom = top + menuHeight;
      const windowsHeight = window.innerHeight;

      if (bottom > windowsHeight) {
        top -= menuHeight;
      }

      // if the menu enters in the head of the table, hide the menu
      if (tableHeadBottomPosition !== null) {
        if (top <= tableHeadBottomPosition) {
          _setIsOpened(false);
        }
      }

      menu.style.top = `${top}px`;
      menu.style.left = `${left}px`;
    }
  }, [isOpend, tableScrollPosition]);

  // GET ACTIONS LIST
  const getActionsList = () => {
    const response = [];
    for (const [key, value] of Object.entries(actions)) {
      response.push(
        <div
          key={`table-action-${key}`}
          className="action"
          onClick={() => onActionSelected({ row, action: value })}
        >
          {value === "Archiver" && <FaArchive size={14} />}
          {value === "Modifier" && <CiEdit size={18} />}
          {value === "Emplois" && <MdOutlineWorkOutline size={18} />}
          {value === "Formations" && <FaWpforms size={18} />}
          {value === "Diplômes" && <MdGrade size={18} />}
          {value === "Inactivités" && <RiZzzLine size={18} />}
          {value === "Congés Bonifiés" && <FaCocktail size={20} />}
          {value}
        </div>
      );
    }
    return response;
  };

  return (
    <ActionsBtn
      onClick={() => _setIsOpened((lastVal) => !lastVal)}
      ref={btnRef}
    >
      <FiMoreVertical />
      {isOpend && getActionsList().length > 0 && (
        <Actions ref={menuRef}>
          {getActionsList().map((action, key) => (
            <div key={key}>{action}</div>
          ))}
        </Actions>
      )}
    </ActionsBtn>
  );
};

export default TableRowActions;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ActionsBtn = styled.button`
  position: relative;
  width: max-content;
  margin-left: auto;
  background-color: transparent;
  border: none;
  margin: 0;
  font-size: 20px;
  cursor: pointer;
`;

const Actions = styled.div`
  position: fixed;
  background-color: ${COLOR_WHITE};
  border: 1px solid ${COLOR_BORDER_IDLE_PAGE};
  width: 150px;
  max-height: 220px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  > div {
    border-bottom: 1px solid ${COLOR_BORDER_IDLE_PAGE};
  }

  .action {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  div {
    color: ${COLOR_TEXT_TABLE_ROW_ACTIONS};
    font-family: "Ubuntu", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.38px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${COLOR_BORDER_IDLE_PAGE};
    }
  }
`;
