import { useEffect, useState } from "react";
import BodyContainer from "../../components/BodyContainer";
import TableComponent from "../../components/table/TableComponent";
import { useAgents } from "../../common/contexts/agentContext";
import styled from "styled-components";
import { COLOR_TEXT } from "../../constants/cts_colors";
import { IAgentInfos } from "../../interfaces/agent";
import {
  changeDateFormatDDMMYYYY,
  convertDateToString,
} from "../../utils/date";
import { ITableData } from "../../interfaces/table";
import { IoIosSearch } from "react-icons/io";

const AnoymisedAgents = () => {
  const { onGetAllAnonymisedAgents } = useAgents();

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  // DATA - VARIABLES
  const [data, _setData] = useState<ITableData | null>(null);
  const [triggerLoadDataFromDB, _setTriggerLoadDataFromDB] =
    useState<boolean>(false);

  const ROW_ACTIONS = {
    restore: "Restaurer",
    anonym: "Anonymiser",
  };
  // SELECT TABLE ROW - VARIABLES
  const [selectedRowIds, _setSelectedRowIds] = useState<Array<number>>([]);

  // MODALS
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  // set title of the page
  useEffect(() => {
    document.title = "Agents Anonymisés | Sorbonne";
  }, []);

  // get and set the table data
  useEffect(() => {
    onGetAllAnonymisedAgents()
      .then((returnData) => {
        console.log(returnData);
        if (returnData) {
          loadTableData(returnData);
        }
      })
      .catch((err) => console.error(err));
  }, [triggerLoadDataFromDB]);

  // load table's data
  const loadTableData = (data: Array<IAgentInfos>) => {
    let rows: any = [];
    const columns: Array<string> = [
      "", //checkbox
      "Matricule",
      "Nom",
      "Prénom",
      "Genre",
      // "État matrimonial",
      "Âge",
      "Date de naissance",
      "Téléphone Professionnel",
      "E-mail",
    ];

    // create the rows
    data.forEach((row) => {
      const rowId = row.id;
      rows.push({
        checkbox: <></>,
        registrationNumber: row.registrationNumber
          ? row.registrationNumber
          : "***",
        lastName: row.lastName ? row.lastName : "***",
        firstName: row.firstName ? row.firstName : "***",
        sex: row.sex,
        // maritalStatus: row.maritalStatus,
        age: row.age ? row.age : "",
        birthDate: changeDateFormatDDMMYYYY(row.birthDate),
        professionalPhoneNumber: row.professionalPhoneNumber
          ? row.professionalPhoneNumber
          : "***",
        email: row.email ? row.email : "***",
        actions: <></>,
      });
    });

    console.log(rows);

    // set the data with the columns and rows
    _setData({
      columns,
      rows,
    });
  };

  return (
    <BodyContainer>
      {/* table */}
      <TableComponent
        hideHeader={true}
        data={data}
        // selectedRowsIdList={selectedRowIds}
        // onRestoreSelectedRows={() =>
        //   _setConfirmModal({
        //     message: "Voulez-vous restaurer les agents sélectionnés ?",
        //     confirmedAction: restoreSelectedRows,
        //   })
        // }
      />
    </BodyContainer>
  );
};

export default AnoymisedAgents;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
