import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { AiOutlineFieldNumber, AiOutlineUser } from "react-icons/ai";
import FormBase from "../../../components/form/FormBase";
import { BsGenderAmbiguous } from "react-icons/bs";
import { useAgents } from "../../../common/contexts/agentContext";
import { IAgentFormValues, IAgentInfos } from "../../../interfaces/agent";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import FormInfoMessage from "../../../components/form/FormInfoMessage";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdAlternateEmail } from "react-icons/md";
import DayCard from "../../../components/DayCard";
import { useTeleworking } from "../../../common/contexts/teleworkingContext";
import {
  IAgentStatusFormValues,
  IAgentStatusInfos,
} from "../../../interfaces/agentStatus";
import { useAgentsStatus } from "../../../common/contexts/agentStatusContext";
import {
  ICategoryFormValues,
  ICategoryInfos,
} from "../../../interfaces/category";
import { useCategories } from "../../../common/contexts/categoryContext";
import { useBodiesGrades } from "../../../common/contexts/bodyGradeContext";
import { IBodyGradeInfos } from "../../../interfaces/bodyGrade";
import { BiCategory } from "react-icons/bi";
import {
  COLOR_BTN_HOVER,
  COLOR_WHITE,
  COLOR_BACKGROUND,
  COLOR_SIDEBAR,
  COLOR_BLACK,
} from "../../../constants/cts_colors";

const AddEditFullAgentForm = ({
  title,
  formData,
  actionString,
  onUpdated,
}: {
  title: string;
  formData?: IAgentInfos | undefined;
  actionString: string;
  onUpdated?: Function;
}) => {
  const initialFormState = {
    firstName: null,
    lastName: null,
    sex: null,
    birthDate: null,
    registrationNumber: null,
    maritalStatus: null,
    professionalPhoneNumber: null,
    observationsDifficultJobs: null,
    observationsParticularSituations: null,
    email: null,
    bonusVacation: null,
    departureDate: null,
    departureReason: null,
  };
  const initialFormStateStatus = {
    type: null,
    startDate: null,
    endDate: null,
    agentId: formData?.id,
    subtype: null,
  };
  const initialCategoryFormState = {
    type: null,
    sigleBody: null,
    body: null,
    sigleGrade: null,
    grade: null,
    agentId: formData?.id,
    bodygradeId: null,
  };
  const [ongletSelected, _setOngletSelected] = useState<string>("general");
  const [formValues, _setFormValues] =
    useState<IAgentFormValues>(initialFormState);
  const [formValuesStatus, _setFormValuesStatus] =
    useState<IAgentStatusFormValues>(initialFormStateStatus);
  const [formValuesCategory, _setFormValuesCategory] =
    useState<ICategoryFormValues>(initialCategoryFormState);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const { onCreateAgent, onUpdateAgent } = useAgents();
  const [isEditMode, _setIsEditMode] = useState<boolean>(false);
  const [isEditModeCategory, _setIsEditModeCategory] = useState<boolean>(false);
  const [isEditModeStatus, _setIsEditModeStatus] = useState<boolean>(false);
  const [day0Checked, _setDay0Checked] = useState<boolean>(false);
  const [day1Checked, _setDay1Checked] = useState<boolean>(false);
  const [day2Checked, _setDay2Checked] = useState<boolean>(false);
  const [day3Checked, _setDay3Checked] = useState<boolean>(false);
  const [day4Checked, _setDay4Checked] = useState<boolean>(false);
  const [day5Checked, _setDay5Checked] = useState<boolean>(false);
  const [day6Checked, _setDay6Checked] = useState<boolean>(false);
  const [status, _setStatus] = useState<IAgentStatusInfos>();
  const [bodiesGrades, _setBodiesGrades] = useState<Array<IBodyGradeInfos>>([]);
  const [category, _setCategory] = useState<ICategoryInfos>();
  const [idCategoryUpdate, _setIdCategoryUpdate] = useState<number>();
  const { onGetAllBodiesGrades } = useBodiesGrades();
  const { onUpdateTeleworking, onGetTeleworkingFromAgent } = useTeleworking();
  const {
    onDeleteAgentStatus,
    onUpdateAgentStatus,
    onCreateAgentStatus,
    onGetAgentStatusFromAgent,
  } = useAgentsStatus();
  const {
    onDeleteCategory,
    onGetCategoryFromAgent,
    onCreateCategory,
    onUpdateCategory,
  } = useCategories();

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);

      onGetTeleworkingFromAgent(formData.id).then((response: any) => {
        // console.log(response);
        if (response) {
          _setDay0Checked(response.days[0]);
          _setDay1Checked(response.days[1]);
          _setDay2Checked(response.days[2]);
          _setDay3Checked(response.days[3]);
          _setDay4Checked(response.days[4]);
          _setDay5Checked(response.days[5]);
          _setDay6Checked(response.days[6]);
        }
      });

      onGetAgentStatusFromAgent(formData.id).then((response: any) => {
        if (response) {
          _setStatus(response);
          _setIsEditModeStatus(true);
          _setFormValuesStatus(response);
        } else {
          _setIsEditModeStatus(false);
          _setFormValuesStatus(initialFormStateStatus);
        }
      });

      onGetCategoryFromAgent(formData.id).then((response: any) => {
        _setCategory(response);
        if (response) {
          _setIsEditModeCategory(true);
          _setFormValuesCategory(response);
        }
      });
    } else {
      _setStatus(undefined);
      _setCategory(undefined);
      _setIsEditMode(false);
      _setIsEditModeStatus(false);
    }

    onGetAllBodiesGrades().then((response: any) => {
      _setBodiesGrades(response);
    });
  }, [formData]);

  useEffect(() => {
    console.log("VALUES", formValues);
  }, [formValues]);

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSubmitInformations();
    await handleSubmitTeletravail();
    await handleSubmitStatus();
    await handleSubmitCategory();

    if (onUpdated) {
      onUpdated();
    }
  };

  // on submit
  const handleSubmitInformations = () => {
    if (isEditMode) {
      // if is on edit mode, update the agent
      return onUpdateAgent(formValues).catch((err) => {
        _setErrorMessage(err);
      });
    } else {
      // if it's not on edit mode, create the agent
      return onCreateAgent(formValues)
        .then((e) => console.log(e))
        .catch((err) => {
          _setErrorMessage(err);
        });
    }
  };

  const handleSubmitTeletravail = async () => {
    // // if is on edit mode, update the agent inactivity
    return onUpdateTeleworking({
      agentId: formData?.id,
      days: [
        day0Checked,
        day1Checked,
        day2Checked,
        day3Checked,
        day4Checked,
        day5Checked,
        day6Checked,
      ],
    });
  };

  const handleSubmitStatus = () => {
    if (status && !formValuesStatus.type) {
      return deleteAgentStatus(status.id);
    }

    if (formData) {
      if (!isEditModeStatus) {
        return onCreateAgentStatus(formValuesStatus)
          .then(() => {
            onGetAgentStatusFromAgent(formData.id).then((response: any) => {
              _setStatus(response);
            });
          })
          .catch((err) => {
            _setErrorMessage(err);
          });
      } else {
        // if is on edit mode, update the agent inactivity
        return onUpdateAgentStatus(formValuesStatus).then(() => {
          onGetAgentStatusFromAgent(formData.id).then((response: any) => {
            _setStatus(response);
          });
        });
      }
    }
  };

  const handleSubmitCategory = () => {
    if (category && !formValuesCategory.bodygradeId) {
      return onDeleteCategory(category.id);
    }
    _setIsEditMode(false);

    if (!isEditModeCategory) {
      return onCreateCategory(formValuesCategory).catch((err) => {
        _setErrorMessage(err);
      });
    } else {
      // if is on edit mode, update the agent inactivity
      return onUpdateCategory(formValuesCategory);
    }
  };

  // format phone number with dot between 2 numbers
  const formatPhoneNumber = (phoneNumber: any) => {
    let numeros = phoneNumber.replace(/\D/g, "");
    let numeroFormate = numeros.match(/.{1,2}/g)?.join(".");

    return numeroFormate || "";
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name !== "professionalPhoneNumber") {
      _setFormValues({
        ...formValues,
        [name]: value ? value : null,
      });
    } else {
      const inputValue = e.target.value;
      const formattedValue = formatPhoneNumber(inputValue);
      // setPhoneNumber(formattedValue); // Met à jour l'état avec le numéro de téléphone formaté
      // setFormattedPhoneNumber(formattedValue); // Met à jour l'état avec le numéro de téléphone formaté pour affichage
      _setFormValues({
        ...formValues,
        ["professionalPhoneNumber"]: formattedValue,
      });
    }
  };

  // delete agent inactivity
  const deleteAgentStatus = (agentStatusId: number) => {
    onDeleteAgentStatus(agentStatusId)
      .then(() => {
        _setFormValuesStatus(initialFormStateStatus);
      })
      .catch((err) => console.error(err));
  };

  // on change
  const handleChangeStatus = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    _setFormValuesStatus({
      ...formValuesStatus,
      [name]: value ? value : null,
    });
  };

  // on change
  const handleChangeCategory = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    // @ts-ignore
    const findCat = bodiesGrades.find((b) => b.id == value);
    if (findCat) {
      _setFormValuesCategory({
        ...formValuesCategory,
        type: findCat.categoryType,
        sigleBody: findCat.sigleBody,
        body: findCat.body,
        sigleGrade: findCat.sigleGrade,
        grade: findCat.grade,
        bodygradeId: findCat.id,
      });
    } else {
      _setFormValuesCategory(initialCategoryFormState);
    }
  };

  return (
    <FormBase title={title}>
      <OngletsBlock>
        <div
          onClick={() => {
            _setOngletSelected("general");
          }}
          style={{
            border:
              ongletSelected === "general" ? `1px solid ${COLOR_BLACK}` : "",
          }}
        >
          <p
            style={{
              textDecoration:
                ongletSelected === "general" ? "underline" : "none",
            }}
          >
            Général
          </p>
        </div>
        <div
          onClick={() => {
            _setOngletSelected("avance");
          }}
          style={{
            border:
              ongletSelected === "avance" ? `1px solid ${COLOR_BLACK}` : "",
          }}
        >
          <p
            style={{
              textDecoration:
                ongletSelected === "avance" ? "underline" : "none",
            }}
          >
            Avancé
          </p>
        </div>
      </OngletsBlock>
      <AllBlock
        style={{
          display: ongletSelected === "general" ? "grid" : "none",
        }}
      >
        <InformationsBlock>
          <FormContainer>
            <FormScrollable>
              <Form onSubmit={handleSubmit}>
                <h3>Informations de l'agent</h3>
                <div className="inputs-container">
                  {/* firstname */}
                  <div>
                    <FormLabel htmlFor="firstName" text="Prénom" />
                    <FormInputContainer
                      icon={<AiOutlineUser />}
                      input={
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder="Prénom"
                          onChange={handleChange}
                          value={
                            formValues.firstName ? formValues.firstName : ""
                          }
                        />
                      }
                    />
                  </div>
                  {/* lastname */}
                  <div>
                    <FormLabel htmlFor="lastName" text="Nom" />
                    <FormInputContainer
                      icon={<AiOutlineUser />}
                      input={
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder="Nom"
                          onChange={handleChange}
                          value={formValues.lastName ? formValues.lastName : ""}
                        />
                      }
                    />
                  </div>
                  {/* sex */}
                  <div>
                    <FormLabel htmlFor="sex" text="Genre" />
                    <FormInputContainer
                      icon={<BsGenderAmbiguous />}
                      input={
                        <select
                          id="sex"
                          value={formValues.sex ? formValues.sex : ""}
                          name="sex"
                          onChange={handleChange}
                        >
                          <option value="">Genre</option>
                          <option value="homme">Homme</option>
                          <option value="femme">Femme</option>
                        </select>
                      }
                    />
                  </div>
                  {/* birth date */}
                  <div>
                    <FormLabel htmlFor="birthDate" text="Date de naissance" />
                    <FormInputContainer
                      input={
                        <input
                          id="birthDate"
                          type="date"
                          onChange={handleChange}
                          name="birthDate"
                          value={
                            formValues.birthDate
                              ? formValues.birthDate.toString()
                              : ""
                          }
                        />
                      }
                    />
                    <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
                  </div>
                  {/* registration number */}
                  <div>
                    <FormLabel htmlFor="registrationNumber" text="Matricule" />
                    <FormInputContainer
                      icon={<AiOutlineFieldNumber />}
                      input={
                        <input
                          type="text"
                          id="registrationNumber"
                          name="registrationNumber"
                          placeholder="Matricule"
                          required
                          onChange={handleChange}
                          value={
                            formValues.registrationNumber
                              ? formValues.registrationNumber
                              : ""
                          }
                        />
                      }
                    />
                  </div>
                  {/* professional phone number */}
                  <div>
                    <FormLabel
                      htmlFor="professionalPhoneNumber"
                      text="Téléphone Professionnel"
                    />
                    <FormInputContainer
                      icon={<IoPhonePortraitOutline />}
                      input={
                        <input
                          type="tel"
                          id="professionalPhoneNumber"
                          name="professionalPhoneNumber"
                          placeholder="06.12.34.56.78"
                          onChange={handleChange}
                          maxLength={14}
                          value={
                            formValues.professionalPhoneNumber
                              ? formValues.professionalPhoneNumber
                              : ""
                          }
                        />
                      }
                    />
                  </div>
                  {/* Email */}
                  <div>
                    <FormLabel htmlFor="email" text="E-mail" />
                    <FormInputContainer
                      icon={<MdAlternateEmail />}
                      input={
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="prénom.nom@exemple.com"
                          onChange={handleChange}
                          value={formValues.email ? formValues.email : ""}
                        />
                      }
                    />
                  </div>

                  {/* form errors */}
                  {errorMessage && (
                    <FormErrorMessageContainer errorMessage={errorMessage} />
                  )}
                </div>
              </Form>
            </FormScrollable>
          </FormContainer>

          <TeletravailBlock>
            {/* days */}
            <div>
              <h3>Jours de télétravail</h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <DayCard
                  text="Lundi"
                  onClick={() => {
                    _setDay0Checked(!day0Checked);
                  }}
                  checked={day0Checked}
                />
                <DayCard
                  text="Mardi"
                  onClick={() => {
                    _setDay1Checked(!day1Checked);
                  }}
                  checked={day1Checked}
                />
                <DayCard
                  text="Mercredi"
                  onClick={() => {
                    _setDay2Checked(!day2Checked);
                  }}
                  checked={day2Checked}
                />
                <DayCard
                  text="Jeudi"
                  onClick={() => {
                    _setDay3Checked(!day3Checked);
                  }}
                  checked={day3Checked}
                />
                <DayCard
                  text="Vendredi"
                  onClick={() => {
                    _setDay4Checked(!day4Checked);
                  }}
                  checked={day4Checked}
                />
                <DayCard
                  text="Samedi"
                  onClick={() => {
                    _setDay5Checked(!day5Checked);
                  }}
                  checked={day5Checked}
                />
                <DayCard
                  text="Dimanche"
                  onClick={() => {
                    _setDay6Checked(!day6Checked);
                  }}
                  checked={day6Checked}
                />
              </div>
            </div>
          </TeletravailBlock>
        </InformationsBlock>
        <StatusBlock>
          <h3>Statut</h3>

          {/* type */}
          <div>
            <FormLabel htmlFor="type" text="Type" />
            <FormInputContainer
              input={
                <input
                  type="text"
                  id="type"
                  name="type"
                  placeholder="Type"
                  onChange={handleChangeStatus}
                  value={formValuesStatus.type ? formValuesStatus.type : ""}
                />
              }
            />
          </div>

          {/* subtype */}
          <div>
            <FormLabel htmlFor="subtype" text="Sous Type" />
            <FormInputContainer
              input={
                <input
                  type="text"
                  id="subtype"
                  name="subtype"
                  placeholder="Sous Type"
                  onChange={handleChangeStatus}
                  value={
                    formValuesStatus.subtype ? formValuesStatus.subtype : ""
                  }
                />
              }
            />
          </div>

          {/* start date */}
          <div>
            <FormLabel htmlFor="startDate" text="Date de début" />
            <FormInputContainer
              input={
                <input
                  id="startDate"
                  type="date"
                  onChange={handleChangeStatus}
                  name="startDate"
                  value={
                    formValuesStatus.startDate
                      ? new Date(formValuesStatus.startDate)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                />
              }
            />
            <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
          </div>

          {/* end date */}
          <div>
            <FormLabel htmlFor="endDate" text="Date de fin" />
            <FormInputContainer
              input={
                <input
                  id="endDate"
                  type="date"
                  onChange={handleChangeStatus}
                  name="endDate"
                  value={
                    formValuesStatus.endDate
                      ? new Date(formValuesStatus.endDate)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                />
              }
            />
            <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
          </div>

          <CategoryBlock>
            <h3>Catégorie</h3>

            {/* marital status */}
            <div>
              <FormLabel htmlFor="bodyGrade" text="Catégorie" />
              <FormInputContainer
                icon={<BiCategory />}
                input={
                  <select
                    id="bodyGrade"
                    value={
                      formValuesCategory.bodygradeId
                        ? formValuesCategory.bodygradeId
                        : ""
                    }
                    name="bodygradeId"
                    onChange={handleChangeCategory}
                  >
                    <option value="">Catégorie</option>
                    {bodiesGrades &&
                      bodiesGrades.map((bodyGrade, key) => {
                        return (
                          <option key={key} value={bodyGrade.id}>
                            {bodyGrade.categoryType} - {bodyGrade.body} (
                            {bodyGrade.sigleBody}) - {bodyGrade.grade} (
                            {bodyGrade.sigleGrade})
                          </option>
                        );
                      })}
                  </select>
                }
              />
            </div>
          </CategoryBlock>
        </StatusBlock>
        <ObservationBlock>
          <h3>Observations</h3>
          {/* Observations difficult jobs */}
          <div>
            <FormLabel
              htmlFor="observationsDifficultJobs"
              text="Observations sur metiers pénibles"
            />
            <FormInputContainer
              input={
                <textarea
                  id="observationsDifficultJobs"
                  name="observationsDifficultJobs"
                  placeholder="Observations sur metiers pénibles"
                  onChange={handleChange}
                  value={
                    formValues.observationsDifficultJobs
                      ? formValues.observationsDifficultJobs
                      : ""
                  }
                />
              }
            />
          </div>
          {/* Observations particular situations */}
          <div>
            <FormLabel
              htmlFor="observationsParticularSituations"
              text="Observations situations particulière"
            />
            <FormInputContainer
              input={
                <textarea
                  id="observationsParticularSituations"
                  name="observationsParticularSituations"
                  placeholder="Observations situations particulière"
                  onChange={handleChange}
                  value={
                    formValues.observationsParticularSituations
                      ? formValues.observationsParticularSituations
                      : ""
                  }
                />
              }
            />
          </div>
          <DepartureBlock>
            <div>
              <FormLabel htmlFor="departureDate" text="Date de départ" />
              <FormInputContainer
                input={
                  <input
                    id="departureDate"
                    type="date"
                    onChange={handleChange}
                    name="departureDate"
                    value={
                      formValues.departureDate
                        ? formValues.departureDate.toString()
                        : ""
                    }
                  />
                }
              />
            </div>
            {/* Departure reason */}
            <div>
              <FormLabel htmlFor="departureReason" text="Motif de départ" />
              <FormInputContainer
                input={
                  <textarea
                    id="departureReason"
                    name="departureReason"
                    placeholder="Motif de départ"
                    onChange={handleChange}
                    value={
                      formValues.departureReason
                        ? formValues.departureReason
                        : ""
                    }
                  />
                }
              />
            </div>
          </DepartureBlock>
        </ObservationBlock>
      </AllBlock>
      <ActionBlock>
        {/* submit btn */}
        <div className="submit-btn-container">
          <Button text={actionString} onClick={handleSubmit} />
        </div>
      </ActionBlock>
    </FormBase>
  );
};

export default AddEditFullAgentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div``;

const FormScrollable = styled.div`
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const AllBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  h3 {
    text-align: center;
    margin-bottom: 16px;
  }
`;

const InformationsBlock = styled.div`
  padding: 32px;
`;

const TeletravailBlock = styled.div`
  padding: 32px;
`;

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 64px;
`;

const StatusBlock = styled.div`
  padding: 32px;
`;

const CategoryBlock = styled.div`
  padding: 32px 0;
`;

const DepartureBlock = styled.div`
  padding: 32px 0;
`;

const ObservationBlock = styled.div`
  padding: 32px;
`;

const OngletsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${COLOR_BACKGROUND};
  border-bottom: 1px solid ${COLOR_SIDEBAR};

  div {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    background-color: ${COLOR_WHITE};
    width: 100%;
    cursor: pointer;

    :hover {
      background-color: ${COLOR_SIDEBAR};
    }
  }

  p {
    text-align: center;
    padding: 8px;
    text-underline-offset: 2px;
  }
`;
