import { ReactNode } from "react";
import styled from "styled-components";
import { COLOR_TITLE } from "../constants/cts_colors";

const BodyContainer = ({
  children,
  title,
  noScroll = false,
}: {
  children: ReactNode;
  title?: string;
  noScroll?: boolean;
}) => {
  return (
    <Body>
      {/* top header */}
      {title && (
        <TopHeader>
          <h1 className="title">{title}</h1>
        </TopHeader>
      )}

      {/* main container */}
      {noScroll && <MainContainer>{children}</MainContainer>}
      {!noScroll && (
        <MainContainerWidthScroll>{children}</MainContainerWidthScroll>
      )}
    </Body>
  );
};

export default BodyContainer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Body = styled.div`
  width: 100%;
  height: 100vh;
  /* padding: 10px; */
`;

const TopHeader = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .title {
    color: ${COLOR_TITLE};
    text-align: center;
    font-size: 1.5rem;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  height: calc(
    100% - 66px
  ); // 100% - (top page header height + bottom space (padding))
  display: flex;
  flex-direction: column;
`;

const MainContainerWidthScroll = styled(MainContainer)`
  width: 100%;
  height: calc(
    100% - 66px
  ); // 100% - (top page header height + bottom space (padding))
  overflow: auto;
`;
